import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Paragraph, Modal } from "@tvg/design-system/web";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import useContentData from "../../hooks/useContentData";
import { setSelectedSlug } from "../../redux/actions";
import type { StoryblokWalkthroughContent, WalkthroughStep } from "../../types";
import { PageWrapper, Image } from "./styled-components";
import NavigationButtons from "./buttons";
import Loading from "./loading";

const WalkthroughModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const { hash, key: locationKey } = useLocation();

  const [slug, setSlug] = useState("");
  const [page, setPage] = useState(1);

  const { contentData, isLoading } =
    useContentData<StoryblokWalkthroughContent>(slug);

  useEffect(() => {
    if (hash.startsWith("#walkthroughs")) {
      setSlug(hash.replace("#", "").replace("-", "/"));
    } else {
      setSlug("");
    }
  }, [hash]);

  const onCloseModal = useCallback(() => {
    if (locationKey !== "default") {
      navigate(-1);
    } else {
      navigate({ hash: "" });
    }
    dispatch(setSelectedSlug("homepage"));
  }, [dispatch, locationKey]);

  return (
    <Modal
      isOpen={!!slug}
      headerProps={{
        title: contentData?.title ?? "Loading...",
        showCloseButton: true
      }}
      padding={isLoading ? 0 : "space-4"}
      onClose={onCloseModal}
      onAfterClose={() => setPage(1)}
      footerContent={
        contentData ? (
          <NavigationButtons
            page={page}
            totalPages={contentData.steps.length}
            doneProps={{
              label: contentData.done_button_title,
              link: contentData.done_button_link.cached_url
            }}
            onChangePage={setPage}
            onCloseModal={onCloseModal}
          />
        ) : (
          <></>
        )
      }
      hasPages
      page={page}
      qaLabel="walkthrough-modal"
      hasMaxHeight={isMobile}
    >
      {isLoading || !contentData ? (
        <Loading />
      ) : (
        <PageWrapper data-qa-label={`page-wrapper-page-${page}`}>
          {contentData.steps && contentData.steps.length > 0 ? (
            <WalkthroughPage content={contentData.steps[page - 1]} />
          ) : (
            <Paragraph>No content available</Paragraph>
          )}
        </PageWrapper>
      )}
    </Modal>
  );
};

const WalkthroughPage = ({ content }: { content: WalkthroughStep }) => (
  <>
    <Image data-qa-label="image" src={content.image.filename} />
    <Paragraph
      qaLabel="title"
      fontFamily="bold"
      fontSize="l"
      textAlign="center"
      color="--fd-colors-content-strong"
      mt="space-4"
      py="space-4"
    >
      {content.title}
    </Paragraph>
    <Paragraph
      qaLabel="description"
      fontSize="m"
      textAlign="center"
      color="--fd-colors-content-default"
    >
      {content.description}
    </Paragraph>
  </>
);

export default WalkthroughModal;
